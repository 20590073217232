
import { prop, Vue } from 'vue-class-component';

class Props {
  fill = prop<boolean>({
      default: false,
      type: Boolean
    });
}
export default class DownloadOverlay extends Vue.with(Props) {}
